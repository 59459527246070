<template>
  <div class="bid-panel-authority">
    <form action="/">
      <van-search
          v-model="userName"
          show-action
          placeholder="请输入搜索关键词"
          @cancel="onCancel"
      />
    </form>
    <el-table
        :data="tableData"
        stripe
        height="150"
        border
        style="width: 96%; margin-left: 2%; margin-top: 10px; text-align: center">
      <el-table-column
          prop="userNo"
          label="用户编码"
          width="120">
      </el-table-column>
      <el-table-column
          prop="userName"
          label="用户名称"
          width="126">
      </el-table-column>
      <el-table-column label="操作" width="130">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">权限管理
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="clearfix">
      <span>已分配权限</span>
    </div>
    <el-table
        :data="tableDataHave"
        stripe
        border
        style="width: 96%; margin-left: 2%; margin-top: 10px; text-align: center">
      <el-table-column
          prop="newsName"
          label="角色名称"
          width="120">
      </el-table-column>
      <el-table-column
          prop="userName"
          label="用户名称"
          width="126">
      </el-table-column>
      <el-table-column label="操作" width="130">
        <template slot-scope="scope">
          <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="deleteEdit(scope.$index, scope.row)">移出
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="clearfix">
      <span>未分配权限</span>
    </div>
    <el-table
        :data="tableDataNot"
        stripe
        border
        style="width: 96%; margin-left: 2%; margin-top: 10px; text-align: center">
      <el-table-column
          prop="newsName"
          label="角色名称"
          width="120">
      </el-table-column>
      <el-table-column
          prop="userName"
          label="用户名称"
          width="126">
      </el-table-column>
      <el-table-column label="操作" width="130">
        <template slot-scope="scope">
          <el-button
              icon="el-icon-plus"
              type="success"
              size="mini"
              @click="addEdit(scope.$index, scope.row)">添加
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import {Toast} from "vant";
import CrmTabBar from "@/components/CrmTabBar";
import {getAuthor} from '@/api/eChnenergyTask';
import {getAuthorHave} from '@/api/eChnenergyTask';
import {addAuthority} from '@/api/eChnenergyTask';
import {deleteAuthority} from '@/api/eChnenergyTask';

export default {
  name: "BidPanelAuthority",
  components: {CrmTabBar},
  data() {
    const generateData = _ => {
      const data = [];
      for (let i = 1; i <= 15; i++) {
        data.push({
          key: i,
          label: `备选项 ${i}`,
          disabled: i % 4 === 0
        });
      }
      return data;
    };
    return {
      freshHeight: 0,
      userName: '',
      tableData: undefined,
      tableDataHave: [],
      tableDataNot: [],
      data: generateData(),
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init()
  },
  watch: {
    //监听排序
    'userName': function () {
      this.tableData = [];
      this.init();
    },
  },
  methods: {
    init() {
      getAuthor({userName: this.userName}).then(res => {
        this.tableData = res
      })
    },
    onCancel() {
      Toast('取消');
    },
    handleEdit(index, row) {
      console.log(row.userNo)
      this.tableDataNot = []
      this.tableDataHave = []
      getAuthorHave({userNo: row.userNo}).then(res => {
        console.log(res)
        res.forEach(item => {
          if (item.tag == "0") {
            this.tableDataNot.push(item)
          } else {
            this.tableDataHave.push(item)
          }
        })
        Toast.success('加载成功！');
      })
    },
    addEdit(index, row) {
      addAuthority({
        userName: row.userName,
        newsName: row.newsName,
        userNo: row.userNo,
        createdPeople: this.$store.getters.userId
      }).then(res => {
        Toast.success('添加成功！');
      })
      setTimeout(() => {
        this.handleEdit(index, row)
      }, 200);
    },
    deleteEdit(index, row) {
      deleteAuthority({
        userName: row.userName,
        newsName: row.newsName,
        userNo: row.userNo,
        createdPeople: this.$store.getters.userId
      }).then(res => {
        Toast.success('删除成功！');
      })
      console.log(row)
      setTimeout(() => {
        this.handleEdit(index, row)
      }, 200);
    }
  }
}
</script>

<style scoped>
.bid-panel-authority {
  background-color: #FFFFFF;
  height: 9999px;
}

.bid-panel-authority .clearfix {
  text-align: left;
  margin-left: 2%;
  margin-top: 2%;
  font-size: 14px;
  font-weight: bolder;
}
</style>



